import React from 'react';
import { graphql } from 'gatsby';
import HubspotForm from 'react-hubspot-form';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Banner from '../components/banner';
import { htmlDecode } from '../Utils';
import Link from '../components/link';

const EventTemplate = ({ data, pageContext }) => {
  const seoTitle = data.strapiEvent.seo
    ? data.strapiEvent.seo.seoTitle
    : data.strapiEvent.title;
  const seoDesc = data.strapiEvent.seo
    ? data.strapiEvent.seo.seoDescription
    : '';
  const cdnUrl = 'https://dlyog079eaher.cloudfront.net';

  let formId = null;
  if (data.strapiEvent.form) {
    const split1 = data.strapiEvent.form.split('formId:');
    const split2 = split1[1].split('\n');
    formId = split2[0].replaceAll('"', '');
  }

  return (
    <Layout
      classNames={`event-template-default single single-event ${data.strapiEvent.id}`}
    >
      <Seo
        title={data.strapiEvent.title}
        seoTitle={seoTitle}
        seoDescription={seoDesc}
      />
      <section className="banner-wrap">
        <div id="banner" className="banner">
          <div
            className="col-12 banner-bg-image ph0 small "
            style={{
              backgroundImage: `url("${
                data.strapiEvent.featuredImage.url.includes(cdnUrl)
                  ? data.strapiEvent.featuredImage.url
                  : cdnUrl + data.strapiEvent.featuredImage.url
              }")`,
              opacity: 1,
            }}
          ></div>
          <div className="banner-overlay" />
          <div className="banner-inside no-overlay">
            <div className="col-12 banner-text text-center">
              {data.strapiEvent.title && (
                <span className="h1-banner-title tcw">
                  {htmlDecode(data.strapiEvent.title)}
                </span>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="content-wrap">
        <div className="mb5 post-type-event">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="article-body">
                  {data.strapiEvent?.hero?.eventSubTitle && (
                    <span className="module-header-small">
                      {htmlDecode(data.strapiEvent.hero.eventSubTitle)}
                    </span>
                  )}
                </div>
                {data.strapiEvent.startDate && (
                  <div className="date db mb.5">
                    <span className="teaser-subhead">Date:</span>
                    <span className="teaser-subhead-light">
                      &nbsp;
                      {`${data.strapiEvent.startDate} ${
                        data.strapiEvent.endDate &&
                        data.strapiEvent.endDate !== data.strapiEvent.startDate
                          ? '- ' + data.strapiEvent.endDate
                          : ''
                      }`}
                    </span>
                  </div>
                )}
                <div className="location db mb3">
                  <span className="teaser-subhead">Location:</span>
                  <br />
                  <span className="teaser-subhead-light">
                    {htmlDecode(data.strapiEvent.locationTitle)}
                  </span>{' '}
                  <br />
                  <span className="teaser-subhead-light">
                    {htmlDecode(data.strapiEvent.street1)}
                  </span>{' '}
                  <br />
                  {data.strapiEvent.street2 &&
                    data.strapiEvent.street2 !== ' ' && (
                      <>
                        <span className="teaser-subhead-light">
                          {htmlDecode(data.strapiEvent.street2)}
                        </span>
                        <br />
                      </>
                    )}
                  <span className="teaser-subhead-light">
                    {`${data.strapiEvent.city}, ${data.strapiEvent.state} ${
                      data.strapiEvent.country
                        ? ' ' + data.strapiEvent.country
                        : ''
                    } ${data.strapiEvent.postalCode}`}
                  </span>
                  <br />
                  {data.strapiEvent.mapLink && (
                    <Link
                      href={data.strapiEvent.mapLink}
                      target="_blank"
                      className="link"
                    >
                      View Map
                    </Link>
                  )}
                </div>
                <div
                  className="article-body copy mt"
                  dangerouslySetInnerHTML={{ __html: data.strapiEvent.content }}
                />
              </div>
              <div className="col-md-12 col-lg-6 form subform sub78">
                <div className="form_with_content_block__form-wrap subform sub9">
                  {data.strapiEvent.formTitle && (
                    <h2 className="form_with_content_block__form-title ft8 bg3">
                      {htmlDecode(data.strapiEvent.formTitle)}
                    </h2>
                  )}
                  {data.strapiEvent.form && formId && (
                    <div className="pt3 ph6 pb5 tac">
                      <HubspotForm
                        portalId="2718424"
                        css=""
                        cssClass="hbspt-form"
                        region="na1"
                        formId={formId}
                        loading={
                          <div className="form-loading">
                            <h3>Loading...</h3>
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default EventTemplate;

export const query = graphql`
  query EventTemplate($id: String!) {
    strapiEvent(id: { eq: $id }) {
      id
      seo {
        excludeFromSitemap
        noFollow
        noIndex
        seoTitle
        seoDescription
      }
      title
      featuredImage {
        url
      }
      hero {
        bannerOverlay
        eventSubTitle
        textColor
      }
      content
      startDate(formatString: "MMMM D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      locationTitle
      street1
      city
      state
      country
      postalCode
      mapLink
      formTitle
      form
    }
  }
`;
